<template>
  <div class="div-block-7 article-content-subpage">
    <div class="ui small breadcrumb">
      <i class="left angle icon divider divider--subpage"></i>
      <router-link :to="{ name: 'activities' }" class="active section text-meta"
        >Alle Angebote</router-link
      >
    </div>
    <div class="div-block-14">
      <h1>Fit for Snow</h1>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <p>
          Um sich auch in den dunklen Herbst-/und Wintermonaten auf die kommende
          Skisaison vorzubereiten, bieten wir mit "Fit for Snow" den passenden
          Kurs an.
        </p>
        <p>
          In zahlreichen und variablen Ganzkörperübungen, deren Intensität mit
          dem eigenen Körpergewicht gesteuert werden können, werden alle
          Muskelgruppen beansprucht und gestärkt.
        </p>
        <p>
          Das Training findet Dienstags um 19:00 Uhr in der Sporthalle der
          Grundschule Oestertal statt. Der Ansprechpartner für das Training ist
          Ralf Pilsner.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fit-for-snow",
  metaInfo: {
    title: "Fit for Snow",
  },
};
</script>
