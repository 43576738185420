<template>
  <div class="div-block-7 article-content-subpage">
    <div class="ui small breadcrumb">
      <i class="left angle icon divider divider--subpage"></i>
      <router-link :to="{ name: 'activities' }" class="active section text-meta"
        >Alle Angebote</router-link
      >
    </div>
    <div class="div-block-14">
      <h1>Ski-/ und Snowboardschule</h1>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <p>
          Dem Skiklub Oestertal stehen einige qualifizierte DSV-Ski- und
          Snowboardlehrer zur Verfügung. Bei unseren Skiausfahrten oder
          Skifreizeiten bieten wir Ski- sowie Snowboardunterricht an oder führen
          als Guides durch das Skigebiet.
        </p>
        <p>Bei Interesse sprich uns einfach an!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nordic",
  metaInfo: {
    title: "Nordic Walking",
  },
};
</script>
