<template>
  <div class="div-block-7 article-content-subpage">
    <div class="ui small breadcrumb">
      <i class="left angle icon divider divider--subpage"></i>
      <router-link :to="{ name: 'activities' }" class="active section text-meta"
        >Alle Angebote</router-link
      >
    </div>
    <div class="div-block-14">
      <h1>Nordic-Walking</h1>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <p>
          Es werden zwei Leistungsgruppen angeboten. Einmal die Softwalk Gruppe,
          die es in ca. 1,5 Stunden etwas gemütlicher und etwas kürzer angehen
          lässt. Dann die Fitnesswalk Gruppe, die es in ca. 1,5 Stunden
          sportlich zügig angehen lässt.
        </p>
        <p>
          Beiden Gruppen ist gemeinsam, das der Spaß und die Bewegung an der
          frischen Luft im Vordergrund steht und nicht der sportliche Ehrgeiz.
        </p>
        <p>
          Die Leitung beider Gruppen erfolgt durch ausgebildete Nordic
          Walking-Trainer.
        </p>
      </div>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <h2>Zeiten</h2>
        <p>
          Treffpunkt ist in der
          <strong
            ><router-link
              :to="{ name: 'event', params: { id: 'oK0DlcJwphTjfx8yUH94' } }"
              >Sommersaison</router-link
            ></strong
          >
          (von ca. Mitte April bis ca. Mitte September) um 18:00 Uhr am
          Sportplatz in Plettenberg-Oesterau.
        </p>
        <p>
          In der
          <strong
            ><router-link
              :to="{ name: 'event', params: { id: 'oK0DlcJwphTjfx8yUH94' } }"
              >Wintersaison</router-link
            ></strong
          >
          (ca. Mitte September bis ca. Mitte April) ist der Treffpunkt um 18:00
          Uhr an der Oesterhalle in Oesterau.
        </p>
        <p>
          Bei Gelegenheit bieten wir auch Sonntagstouren dann. Diese werden dann
          entsprechend vorher angekündigt.
        </p>
      </div>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <h2>Ansprechpartner</h2>
        <strong>Uwe Ziegler</strong> ist der Ansprechpartner für das Nordic
        Walking-Angebot und kann per Telefon unter
        <a href="tel:+49239170642">02391/70642</a> erreicht werden.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nordic",
  metaInfo: {
    title: "Nordic Walking",
  },
};
</script>
