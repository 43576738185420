<template>
  <div>
    <div class="div-block-7 article-content-subpage">
      <div class="ui small breadcrumb">
        <i class="left angle icon divider divider--subpage"></i>
        <router-link
          :to="{ name: 'activities' }"
          class="active section text-meta"
          >Alle Angebote</router-link
        >
      </div>
      <div class="div-block-14">
        <h1>Mountainbiken</h1>
      </div>
      <div class="block-article--intro">
        <div class="block-article-content">
          <p>
            Es werden insgesamt drei Leistungsgruppen angeboten. Alle Gruppen
            finden nur in der Sommersaison von ca. Mai bis nach dem P-Weg
            Marathon Mitte September statt, und dann jeden Dienstag.
          </p>
        </div>
      </div>
      <div class="block-article--intro">
        <div class="block-article-content">
          <h2>Gruppen</h2>
          <p>
            Die
            <strong
              ><router-link
                :to="{ name: 'event', params: { id: '1yQuUwl93ALeGsAKTD6V' } }"
                >MTB-Gruppe „Beginner”</router-link
              >
            </strong>
            richtet sich an alle Biker, die z B. nach der Wintersaison wieder
            neu einsteigen wollen, oder die überhaupt mit dem Mountainbiken
            anfangen wollen. Die Touren sind technisch unauffällig und vom Tempo
            und der Distanz eher gemütlich. Abfahrt ist um 17:30 Uhr an der
            alten Feuerwache (Jugendzentrum) in Plettenberg.
          </p>
          <p>
            Die
            <strong
              ><router-link
                :to="{ name: 'event', params: { id: 'y3eOTLWOI1JmLPvfVSP2' } }"
                >MTB-Gruppe „Advanced”</router-link
              >
            </strong>
            richtet sich an alle an alle Biker, die ihre Mountainbikes sicher
            beherrschen, bereits über eine gewisse Kondition verfügen und auch
            vor technischen Passage nicht zurück schrecken. Abfahrt ist um 18:00
            Uhr an der alten Feuerwache (Jugendzentrum) in Plettenberg.
            <i
              >E-MTBs sind - mit der entsprechenden Rücksicht auf Biker:innen
              mit „Bio”-Motor - in dieser Gruppe ebenfalls willkommen.</i
            >
          </p>
          <p>
            Wenn der Dienstag-Abend zeitlich nicht passt oder du ergänzend
            ganzjährig eine geführte Tour mit dem SKÖ unternehmen möchtest,
            steht die
            <strong>
              <router-link
                :to="{ name: 'event', params: { id: '03JjDQ1s5Iejg1KQcTbd' } }"
                >MTB-Gruppe „die fünfte Gruppe”</router-link
              ></strong
            >
            im Wochenplan zur Verfügung. Treffpunkt ist Donnerstags um 10:00 Uhr
            vor Eis Sagui im Plettenberg Stadtzentrum.
            <i
              >E-MTBs sind - mit der entsprechenden Rücksicht auf Biker:innen
              mit „Bio”-Motor - in dieser Gruppe ebenfalls willkommen.</i
            >
          </p>
          <p>
            Unsere
            <strong>
              <router-link
                :to="{ name: 'event', params: { id: 'xzXbL0smQpySGSBPw5MK' } }"
                >MTB-Familientour</router-link
              ></strong
            >
            am jeweils letzten Sonntag im Monat, eine 2-3 stündige
            Mountainbiketour, ist ideal für die ganze Familie und ab der Saison
            2024 neu im Angebot. E-Bikes sind auch hier willkommen und die Tour
            ist für Kinder ab der dritten Klasse geeignet.
          </p>
          <p>
            Allen Gruppen ist gemeinsam, das der Spaß und die Bewegung an der
            frischen Luft in einer Gruppe gleichgesinnter im Vordergrund stehen.
          </p>
          <p>
            Die Leitung aller Gruppen erfolgt durch ausgebildete Mountainbike
            Scouts.
          </p>
        </div>
      </div>
      <div class="block-article--intro">
        <div class="block-article-content">
          <h2>Benachrichtigungen per SMS und WhatsApp</h2>
          Über die oben verlinkten MTB-Gruppen informieren wir per SMS, wenn
          eine Ausfahrt z.B. wegen Gewitter kurzfristig nicht stattfinden kann.
          Über unsere
          <strong
            ><a
              href="https://chat.whatsapp.com/Bw2jL51iApZKa6Dse4LLJA"
              target="_blank"
              >WhatsApp-Community Skiklub Oestertal</a
            ></strong
          >
          informieren wir direkt in unserer
          <strong
            ><a
              href="https://chat.whatsapp.com/G3tyCxMclq5ItOQQd0izqV"
              target="_blank"
              >WhatsApp-Gruppe Mountainbiken</a
            ></strong
          >
          darüber und informieren zudem über weitere MTB-News beim SKÖ.
        </div>
      </div>
      <div class="block-article--intro">
        <div class="block-article-content">
          <h2>Technikkurse</h2>
          Abgerundet wird das Angebot durch Technikkurse für Anfänger und
          Fortgeschrittene, durch gemeinsame Ausfahrten aller Gruppen und durch
          gelegentliche Ausfahrten am Wochenende.
        </div>
      </div>
      <div class="block-article--intro">
        <div class="block-article-content">
          <h2>Ansprechpartner</h2>
          <strong>Stefan Friedrich</strong> ist der verantwortliche
          Ansprechpartner für das Mountainbiken und kann unter
          <a href="tel:+491705348206">0170/5348206</a> erreicht werden.
        </div>
      </div>
    </div>
    <!-- [END] content -->
    <EventsRelatedMtb />
  </div>
</template>

<script>
import EventsRelatedMtb from "@/components/events/EventsRelatedMtb";

export default {
  name: "mountainbike",
  metaInfo: {
    title: "Mountainbiken",
  },
  components: {
    EventsRelatedMtb,
  },
};
</script>
