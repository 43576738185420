<template>
  <div>
    <div class="div-block-7 article-content-subpage">
      <div class="div-block-14">
        <h1>Unsere Angebote</h1>
      </div>
      <div
        class="block-article--intro"
        style="margin-bottom: 0; padding-bottom: 0"
      >
        <div class="block-article-content">
          <div class="w-richtext">
            <p>
              Neben regelmäßigen Veranstaltungen bietet der Skiklub über die
              Sommer-/und Wintermonate verschiedene Angebote an. Wähle ein
              Angebot aus, um weitere Informationen zu erhalten.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section-related-content" style="margin-bottom: 20px">
      <div class="w-dyn-list">
        <div class="w-dyn-items w-row">
          <!-- [START] Mountainbiken -->
          <router-link
            :to="{ name: 'mountainbike' }"
            class="collection-item-3 w-dyn-item w-col w-col-4"
          >
            <div
              class="link-block-2 w-inline-block"
              style="
                background-image: url('https://images.unsplash.com/photo-1481995382552-5ebe5472b219?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80');
              "
            >
              <div class="div-block-20">
                <div class="div-block-21">
                  <h4 class="heading-3">Mountainbiken</h4>
                </div>
              </div>
            </div>
          </router-link>
          <!-- [END] Mountainbiken -->
          <!-- [START] Nordic-Walking -->
          <router-link
            :to="{ name: 'nordic-walking' }"
            class="collection-item-3 w-dyn-item w-col w-col-4"
          >
            <div
              class="link-block-2 w-inline-block"
              style="
                background-image: url('https://images.unsplash.com/photo-1487956382158-bb926046304a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80');
              "
            >
              <div class="div-block-20">
                <div class="div-block-21">
                  <h4 class="heading-3">Nordic-Walking</h4>
                </div>
              </div>
            </div>
          </router-link>
          <!-- [END] Nordic-Walking -->
          <!-- [START] Tanzgruppen -->
          <router-link
            :to="{ name: 'dancing' }"
            class="collection-item-3 w-dyn-item w-col w-col-4"
          >
            <div
              class="link-block-2 w-inline-block"
              style="
                background-image: url('https://images.unsplash.com/photo-1522642888367-8d98750c243c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80');
              "
            >
              <div class="div-block-20">
                <div class="div-block-21">
                  <h4 class="heading-3">Tanzgruppen</h4>
                </div>
              </div>
            </div>
          </router-link>
          <!-- [END] Tanzgruppen -->
          <!-- [START] Fit For Snow -->
          <router-link
            :to="{ name: 'fit-for-snow' }"
            class="collection-item-3 w-dyn-item w-col w-col-4"
          >
            <div
              class="link-block-2 w-inline-block"
              style="
                background-image: url('https://images.unsplash.com/photo-1544216717-3bbf52512659?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80');
              "
            >
              <div class="div-block-20">
                <div class="div-block-21">
                  <h4 class="heading-3">Fit for Snow</h4>
                </div>
              </div>
            </div>
          </router-link>
          <!-- [END] Fit For Snow -->
          <!-- [START] Ski-/ und Snowboardschule -->
          <router-link
            :to="{ name: 'skischool' }"
            class="collection-item-3 w-dyn-item w-col w-col-4"
          >
            <div
              class="link-block-2 w-inline-block"
              style="
                background-image: url('https://images.unsplash.com/photo-1517130038641-a774d04afb3c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80');
              "
            >
              <div class="div-block-20">
                <div class="div-block-21">
                  <h4 class="heading-3">Ski-/ und Snowboardschule</h4>
                </div>
              </div>
            </div>
          </router-link>
          <!-- [END] Ski-/ und Snowboardschule -->
          <!-- [START] Skifreizeiten -->
          <router-link
            :to="{ name: 'ski-holidays' }"
            class="collection-item-3 w-dyn-item w-col w-col-4"
          >
            <div
              class="link-block-2 w-inline-block"
              style="
                background-image: url('https://images.unsplash.com/photo-1505873346750-69ce3daeb225?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80');
              "
            >
              <div class="div-block-20">
                <div class="div-block-21">
                  <h4 class="heading-3">Skifreizeiten</h4>
                </div>
              </div>
            </div>
          </router-link>
          <!-- [END] Skifreizeiten-->
          <!-- [START] Ausflüge -->
          <router-link
            :to="{ name: 'excursions' }"
            class="collection-item-3 w-dyn-item w-col w-col-4"
          >
            <div
              class="link-block-2 w-inline-block"
              style="
                background-image: url('https://images.unsplash.com/photo-1492352030789-c311213284bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80');
              "
            >
              <div class="div-block-20">
                <div class="div-block-21">
                  <h4 class="heading-3">Ausflüge</h4>
                </div>
              </div>
            </div>
          </router-link>
          <!-- [END] Ausflüge-->
        </div>
      </div>
    </div>

    <div class="div-block-7 article-content-subpage">
      <div class="block-article--intro">
        <div class="block-article-content">
          <div class="w-richtext">
            <p>
              Falls es Fragen zu einem unserer Angebote gibt, stehen die
              jeweiligen Ansprechpartner aus den Bereichen gerne zur Verfügung.
              Darüber hinaus erreicht man uns über die Seite
              <router-link :to="{ name: 'contact' }">Kontakt</router-link>
              jederzeit.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "activities",
  metaInfo: {
    title: "Unsere Angebote",
  },
};
</script>

<style scoped>
.heading-3 {
  margin-bottom: 0;
}
</style>
