<template>
  <div class="div-block-7 article-content-subpage">
    <div class="ui small breadcrumb">
      <i class="left angle icon divider divider--subpage"></i>
      <router-link :to="{ name: 'activities' }" class="active section text-meta"
        >Alle Angebote</router-link
      >
    </div>
    <div class="div-block-14">
      <h1>Tanzgruppen</h1>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <p>
          Wir freuen uns, dass wir eine weitere Sportart in unser Programm
          aufnehmen konnten.
        </p>
        <p>
          Anfang 2016 steht uns ein Trainerteam für den Bereich Tanzsport zur
          Verfügung. Trainiert wird derzeit in der Dorfhalle Landemert.
          Tanzschritte und komplette Choreographien werden in drei verschiedenen
          Altersgruppen einstudiert.
        </p>
      </div>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <h2>Gruppen</h2>
        <h3>Dancing Butterfly's</h3>
        <p>
          <strong>Gruppe 1</strong> · 15:00 – 16:00 Uhr (Kinder von 2 – 5
          Jahren)<br />
          <strong>Gruppe 2 </strong> · 16:00 – 17:00 Uhr (Kinder/Jugendliche von
          11 - 17 Jahren)
        </p>
        <h3>FightStepper</h3>
        <p>18:00 – 19:30 Uhr (Kinder/Jugendliche von 11 - 17 Jahren)</p>
        <p>
          Ihr Kind kann gerne nach Vereinbarung mit Frau Scherweit an einem
          Probetraining teilnehmen.
        </p>
      </div>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <h2>Ansprechpartner</h2>
        <p>
          <strong>Tanja Scherweit</strong> ist die Ansprechpartnerin für die
          Tanzgruppen und kann per Telefon unter
          <a href="tel:+491714866480">0171/4866480</a> erreicht werden.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dancing",
  metaInfo: {
    title: "Tanzgruppen",
  },
};
</script>
