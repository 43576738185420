<template>
  <div class="div-block-7 article-content-subpage">
    <div class="ui small breadcrumb">
      <i class="left angle icon divider divider--subpage"></i>
      <router-link :to="{ name: 'activities' }" class="active section text-meta"
        >Alle Angebote</router-link
      >
    </div>
    <div class="div-block-14">
      <h1>Ausflüge</h1>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <p>
          In unserem Ganzjahresprogramm bieten wir unterschiedliche
          Tagesausflüge an wie z. B.:
        </p>
        <ul>
          <li>Kanu fahren</li>
          <li>Wasserskilaufen</li>
          <li>Wandern</li>
          <li>Busfahrten in die Skigebiete im Hochsauerland</li>
        </ul>
        <p>
          Die jeweiligen Ausflüge sind rechtzeitig in den
          <router-link :to="{ name: 'events' }">Terminen</router-link> zu
          finden.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "excursions",
  metaInfo: {
    title: "Ausflüge",
  },
};
</script>
